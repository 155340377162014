import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import background from "../images/banner3.jpg";
import{Container,Row,Col,Card,Button} from "react-bootstrap"
import Neubau from "../images/Neubau.jpg"
import Umbau from "../images/Umbau.jpg"
import Isolieren from "../images/Isolieren.jpg"
import SPRITZARBEITEN from "../images/SPRITZARBEITEN.jpg"
import SCHIMMEL from "../images/SCHIMMEL.jpg"
import {Link} from 'gatsby'

const ThirdPage = () => (
  <Layout>
    <SEO title="Angebote" />
    <div className="background-img-about" style={{ backgroundImage: `url(${background})`}}>
      <div header-about-uns><h1 className="header-about-uns">swissartmaler</h1></div>
      </div>
      <Container className="angebote-card" >
        <Row>
          <Col>
          <Card style={{ width: '18rem' }}>
          <Card.Title className="card-tittle">Umbau und Renovation</Card.Title>
          <Card.Img className="card-img "variant="top" src={Umbau} />
          <Card.Body className="card-text">
          <Card.Text>
          Innere Malerarbeiten
          <hr/>
          Fassadenarbeiten
          <hr/>
          Fassadenarbeiten
          <hr/>
          Schimmelsanierungen
          <hr/>
          Tapezierarbeiten
          <hr/>
          Spritzarbeiten
          <hr/>
          Balkon und Bodenbeschichtungen
          <hr/>
          Graffitischutz
          <hr/>
          Gipser und Verputzarbeiten
          <hr/>
          Schützenwerte Objekte
          </Card.Text>
          <Link className="card-link" to="/kontakt"variant="primary">Lessen Sie mehr</Link>
          </Card.Body>
          </Card>
          </Col>
          <Col>
          <Card style={{ width: '18rem' }}>
          <Card.Title className="card-tittle">Neubau</Card.Title>
          <Card.Img className="card-img "variant="top" src={Neubau} />
          <Card.Body className="card-text">
          <Card.Text>
          Innere Malerarbeiten
          <hr/>
          Fassadenarbeiten
          <hr/>
          Tapezierarbeiten
          <hr/>
          Spritzarbeiten
          <hr/>
          Balkon und Bodenschichtungen
          <hr/>
          Graffitischutz
          <hr/>
          Gipser und Verputzarbeiten
          </Card.Text>
          <Link className="card-link" to="/kontakt"variant="primary">Lessen Sie mehr</Link>
          </Card.Body>
          </Card>
          </Col>
          <Col>
          <Card style={{ width: '18rem' }}>
          <Card.Title className="card-tittle">Isolieren</Card.Title>
          <Card.Img className="card-img "variant="top" src={Isolieren} />
          <Card.Body className="card-text">
          <Card.Text>
          Innenisolation
          <hr/>
          Schall / Brandschutz
          <hr/>
          Musterservice
          <hr/>
          Beton / Korrosionschutz
          </Card.Text>
          <Link className="card-link" to="/kontakt"variant="primary">Lessen Sie mehr</Link>
          </Card.Body>
          </Card>
          </Col>
          <Col className="second-card">
          <Card style={{ width: '18rem' }}>
          <Card.Title className="card-tittle">Spritzarbe</Card.Title>
          <Card.Img className="card-img "variant="top" src={SPRITZARBEITEN} />
          <Card.Body className="card-text">
          <Card.Text>
          Türen, Zargen
          <hr/>
          Jalousinen
          <hr/>
          Fertigbauteile
          <hr/>
          Dekorelemente
          </Card.Text>
          <Link className="card-link" to="/kontakt"variant="primary">Lessen Sie mehr</Link>
          </Card.Body>
          </Card>
          </Col>
          <Col className="second-card">
          <Card style={{ width: '18rem' }}>
          <Card.Title className="card-tittle-last">Schimmel und Wasserschaden</Card.Title>
          <Card.Img className="card-img "variant="top" src={SCHIMMEL} />
          <Card.Body className="card-text">
          <Card.Text>
          Farben
          <hr/>
          Entfeuchten
          <hr/>
          Neuverputzen
          <hr/>
          Renovationen
          </Card.Text>
          <Link className="card-link" to="/kontakt"variant="primary">Lessen Sie mehr</Link>
          </Card.Body>
          </Card>
          </Col>
        </Row>
      </Container>
  </Layout>
)

export default ThirdPage
